import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faVideo, faGlobe, faPodcast, faMobileScreen, faMusic } from '@fortawesome/free-solid-svg-icons';
import './Resources.css';

const Resources = () => {
  // Sample book data - you can replace these with your actual books
  const books = [
    {
      title: "Beyond Human: Fully Identified in the New Creation",
      image: "/images/beyond-human.jpg",
      link: "https://www.amazon.com/Beyond-Human-Fully-Identified-Creation/dp/0994433557"
    },
    {
      title: "Limitless: Living as an Overcomer",
      image: "/images/limitless-nancy.jpg",
      link: "https://www.amazon.com/Limitless-Living-Overcomer-Nancy-Coen/dp/1732163863"
    },
    {
      title: "Mystical Union: Stuff They Never Told You About the Finished Work of the Cross",
      image: "/images/mystical-union.jpg",
      link: "https://www.amazon.com/Mystical-Union-Stuff-never-finished/dp/097708261X"
    },
    {
      title: "The Man Who Talked With Angels - Second Edition",
      image: "/images/angels-roland-buck.jpg",
      link: "https://www.amazon.com/Man-Who-Talked-Angels-Second/dp/B0BPWC2KJ6"
    }
  ];

  // Sample placeholder music including the specific link
  const placeholderMusic = [
    {
      title: "Jesus Culture | Holy Spirit",
      link: "https://youtu.be/qNwnOfZ5N8A?si=xZ8e4S4uazc6I7kg",
      image: "/images/Holy-Spirit.png"
    },
    { title: "Soaking Music Set", link: "#" },
    { title: "Live Worship Recording", link: "#" },
    { title: "Declarations Soundtrack", link: "#" },
  ];

  // Sample placeholder videos
  const placeholderVideos = [
    {
      title: "Prophetic Insight Video 1",
      thumbnail: "/images/placeholder-video.jpg",
      link: "#"
    },
    {
      title: "Ministry Update Video 2",
      thumbnail: "/images/placeholder-video.jpg",
      link: "#"
    },
    {
      title: "Teaching Series Episode 3",
      thumbnail: "/images/placeholder-video.jpg",
      link: "#"
    },
    {
      title: "Testimony and Encouragement",
      thumbnail: "/images/placeholder-video.jpg",
      link: "#"
    }
  ];

  // Sample placeholder websites
  const placeholderWebsites = [
    {
      title: "Prophetic Training Resource",
      image: "/images/placeholder-website.jpg",
      link: "#"
    },
    {
      title: "Ministry Partner Portal",
      image: "/images/placeholder-website.jpg",
      link: "#"
    },
    {
      title: "Spiritual Growth Tools",
      image: "/images/placeholder-website.jpg",
      link: "#"
    },
    {
      title: "Kingdom Principles Library",
      image: "/images/placeholder-website.jpg",
      link: "#"
    }
  ];

  // Sample placeholder podcasts
  const placeholderPodcasts = [
    {
      title: "Prophetic Roundtable Discussions",
      image: "/images/placeholder-podcast.jpg",
      link: "#"
    },
    {
      title: "Weekly Devotional Series",
      image: "/images/placeholder-podcast.jpg",
      link: "#"
    },
    {
      title: "Guest Speaker Interviews",
      image: "/images/placeholder-podcast.jpg",
      link: "#"
    },
    {
      title: "Bible Study Commentary",
      image: "/images/placeholder-podcast.jpg",
      link: "#"
    }
  ];

  // Sample placeholder apps
  const placeholderApps = [
    {
      title: "Daily Prophetic Word App",
      image: "/images/placeholder-app.jpg",
      link: "#"
    },
    {
      title: "Prayer Partner Finder",
      image: "/images/placeholder-app.jpg",
      link: "#"
    },
    {
      title: "Scripture Memorization Tool",
      image: "/images/placeholder-app.jpg",
      link: "#"
    },
    {
      title: "Ministry Event Calendar",
      image: "/images/placeholder-app.jpg",
      link: "#"
    }
  ];

  return (
    <>
      {/* Books Section - Card Background */}
      <section className="section-padding resource-section card-bg">
        <Container>
          <h1 className="section-title">Resources</h1>

          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faBook} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Books</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Some of our favorites</p>
            </div>
          </div>

          <Row className="book-gallery-small justify-content-start g-3">
            {books.map((book, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={book.link} target="_blank" rel="noopener noreferrer">
                    <img 
                      src={book.image} 
                      alt={book.title}
                      className="book-image"
                    />
                    <div className="book-title small">{book.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Music Section - Main Background */}
      <section className="section-padding resource-section main-bg">
        <Container>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faMusic} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Music</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Worship and Soaking</p>
            </div>
          </div>
          <Row className="book-gallery-small justify-content-start g-3">
            {placeholderMusic.map((music, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={music.link} target="_blank" rel="noopener noreferrer" title={music.title}>
                    {/* Conditionally render image or placeholder */}
                    {music.image ? (
                      <img 
                        src={music.image} 
                        alt={music.title}
                        className="book-image" // Use same class as book images
                      />
                    ) : (
                      <div className="placeholder-media">
                        <FontAwesomeIcon icon={faMusic} className="placeholder-icon" />
                      </div>
                    )}
                    <div className="book-title small">{music.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <p className="coming-soon-text">More music coming soon!</p>
          </div>
        </Container>
      </section>

      {/* Videos Section - Card Background */}
      <section className="section-padding resource-section card-bg">
        <Container>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faVideo} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Videos</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Teachings and Testimonies</p>
            </div>
          </div>

          <Row className="book-gallery-small justify-content-start g-3">
            {placeholderVideos.map((video, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={video.link} target="_blank" rel="noopener noreferrer">
                    <div className="placeholder-media">
                      <FontAwesomeIcon icon={faVideo} className="placeholder-icon" />
                    </div>
                    <div className="book-title small">{video.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <p className="coming-soon-text">More videos coming soon!</p>
          </div>
        </Container>
      </section>

      {/* Websites Section - Main Background */}
      <section className="section-padding resource-section main-bg">
        <Container>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faGlobe} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Websites</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Helpful Online Resources</p>
            </div>
          </div>

          <Row className="book-gallery-small justify-content-start g-3">
            {placeholderWebsites.map((website, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={website.link} target="_blank" rel="noopener noreferrer">
                    <div className="placeholder-media">
                      <FontAwesomeIcon icon={faGlobe} className="placeholder-icon" />
                    </div>
                    <div className="book-title small">{website.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <p className="coming-soon-text">Website links coming soon!</p>
          </div>
        </Container>
      </section>

      {/* Podcasts Section - Card Background */}
      <section className="section-padding resource-section card-bg">
        <Container>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faPodcast} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Audio Podcasts</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Listen On The Go</p>
            </div>
          </div>

          <Row className="book-gallery-small justify-content-start g-3">
            {placeholderPodcasts.map((podcast, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={podcast.link} target="_blank" rel="noopener noreferrer">
                    <div className="placeholder-media">
                      <FontAwesomeIcon icon={faPodcast} className="placeholder-icon" />
                    </div>
                    <div className="book-title small">{podcast.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <p className="coming-soon-text">Podcast episodes coming soon!</p>
          </div>
        </Container>
      </section>

      {/* Apps Section - Main Background */}
      <section className="section-padding resource-section main-bg">
        <Container>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faMobileScreen} size="lg" className="me-2 text-primary" />
              <h2 className="mb-0 fs-4">Mobile Apps</h2>
              <span className="mx-2 subtitle-text small mb-0">|</span>
              <p className="subtitle-text mb-0 small">Ministry In Your Pocket</p>
            </div>
          </div>

          <Row className="book-gallery-small justify-content-start g-3">
            {placeholderApps.map((app, index) => (
              <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
                <div className="book-card-small">
                  <a href={app.link} target="_blank" rel="noopener noreferrer">
                    <div className="placeholder-media">
                      <FontAwesomeIcon icon={faMobileScreen} className="placeholder-icon" />
                    </div>
                    <div className="book-title small">{app.title}</div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <p className="coming-soon-text">Mobile apps coming soon!</p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Resources; 